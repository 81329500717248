body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #E8C872, #FEB47B);
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo {
  width: 80px;
  height: auto;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-right: 30px;
}

.nav-links a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.nav-btn {
  padding: 10px 20px;
  background-color: #E8491D;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s;
}

.nav-btn:hover {
  background: #333;
  transform: scale(1.05);
}

.hero {
  color: #000;
  padding: 20px 0;
  text-align: center;
}

.hero h1 {
  margin: 10px 0;
}

.hero .btn {
  margin-top: 20px;
  margin-bottom: -20px;
}

.services {
  padding: 50px 0;
  text-align: center;
}

.services h2 {
  margin-bottom: 20px;
}

.services p {
  margin-bottom: 50px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.service-item {
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background: #E8491D;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s;
}

.btn:hover {
  background: #333;
  transform: scale(1.05);
}

.portfolio {
  padding: 50px 0;
  text-align: center;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.portfolio-item {
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.portfolio-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.portfolio-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.testimonials {
  padding: 50px 0;
  text-align: center;
  background-color: #f4f4f4;
}

.testimonials h2 {
  margin-bottom: 20px;
}

.testimonial-image {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.testimonial-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact h2 {
  text-align: center;
  margin-bottom: 20px;
}

.contact form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.contact form input,
.contact form textarea {
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.contact button {
  display: block;
  width: auto;
  padding: 15px 30px;
  background: #E8491D;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s;
  margin: 0 auto;
}

.contact-info {
  text-align: center;
  line-height: 1.8;
  margin-bottom: 20px;
}

.footer-links,
.social-links {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 0;
  margin: 10px 0;
}

.footer-links a,
.social-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover,
.social-links a:hover {
  color: #E8491D;
}

footer {
  background-color: #637A9F;
  color: white;
  padding: 20px 0;
  text-align: center;
}

@media (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    align-items: center;
  }

  .nav-links li {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .services-grid,
  .portfolio-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .nav-links {
    flex-direction: column;
    align-items: center;
  }

  .nav-links li {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .services-grid,
  .portfolio-grid {
    grid-template-columns: 1fr;
  }

  .nav-container {
    flex-direction: column;
    align-items: center;
  }

  .nav-container .logo {
    margin-bottom: 10px;
  }
}
